import { Brand } from './typings'

const gemoney: Brand = {
  clientInfo: {
    displayName: 'Synchrony Financial',
    accountNumberLengths: [16],
    footerDisplay: 'SynchronyFinancial.com',
    footerLink: 'https://www.SynchronyFinancial.com/',
    applicationUrl: 'https://www.synchronybank.com/creditcard/learnmore/',
    dsecApigeeClientId: {
      development: 'zmiGCmB0k22dZASEQuRITmkeW1y6p57l',
      production: 'Wmerd2ZBZALKmwZ0ZqRzHtgK7ahGaTZj'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Synchrony Financial credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Synchrony Financial en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'SynchronyBank.com',
    hostname: 'www.synchronymastercard.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: false,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Synchrony Financial Mastercard is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Synchrony Financial Mastercard. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of Synchrony Financial Credit Cards.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      dualcard: '1-800-944-7310',
      fraud: '1-800-944-7310',
      customerService: '1-800-944-7310',
      plcc: ''
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@gemoney/en/html/ess018688.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@gemoney/en/html/ess018687.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@gemoney/en/html/ess018685.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@gemoney/en/html/ess018683.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    field: {
      icon: '#333333',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#333333',
      label: '#333333'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#3b75d9'
      },
      secondary: {
        hover: '#e5efff'
      },
      background: '#448aff'
    },
    checkbox: {
      background: '#3E8529',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#343642',
    bodyBackground: '#FFFFFF',
    iconBackground: '#CACACA',
    headerBackground: '#333333',
    modalBackground: '#ffffff',
    formBackground: '',
    headerDivider: '#343642',
    footerDivider: '#FBC600',
    contrastingText: '#ffffff',
    bodyText: '#333333',
    languageSelector: '#ffffff',
    focus: '#003d50',
    link: '#3E8529',
    footer: {
      background: '#ffffff',
      text: '#333333',
      link: '#333333'
    }
  },
  images: {
    logo: {
      en: '/assets/gemoney/logo.png',
      es: '/assets/gemoney/logo.png'
    },
    hero: '/assets/gemoney/background.png',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/gemoney/promo.png',
      es: '/assets/gemoney/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default gemoney
